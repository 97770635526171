import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const useNotification = () => {
	const [conf, setConf] = useState<{
		msg: string;
		variant: 'info' | 'default' | 'error' | 'success' | 'warning' | undefined;
	}>({ msg: '', variant: 'default' });
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const action = useCallback(
		(key: string | number) => (
			<IconButton
				onClick={() => {
					closeSnackbar(key);
				}}
			>
				<CloseIcon sx={{ color: '#fff' }} />
			</IconButton>
		),
		[closeSnackbar],
	);

	useEffect(() => {
		if (conf?.msg) {
			enqueueSnackbar(conf.msg, {
				variant: conf.variant,
				autoHideDuration: 3000,
				anchorOrigin: { horizontal: 'right', vertical: 'top' },
				action,
				onClose: () => {
					setConf({ msg: '', variant: undefined });
				},
			});
		}
	}, [conf, enqueueSnackbar, action]);
	return [setConf];
};

export default useNotification;
