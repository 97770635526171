import React from 'react';
import Image from 'next/image';
import {
	Theme,
	MenuItem,
	Typography,
	InputLabel,
	Autocomplete as MuiAutocomplete,
	Tooltip,
	Grid,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import DropdownIcon from '@/assets/icons/Dropdown.svg';
import { TextField } from './TextField';

type AutocompletePropsType = {
	name?: string;
	label: string;
	placeholder?: string;
	options?: any;
	optionKey?: string;
	onChange?: (_arg: { target: { name: string; value: any } }) => void;
	onBlur?: (_event: React.FocusEvent<HTMLInputElement, Element>) => void;
	onInputChange?: (value: string) => void;
	onClose?: () => void;
	value?: Record<string, any>[] | Record<string, any> | null;
	customStyle?: Record<string, any>;
	required?: boolean;
	error?: boolean;
	helperText?: string | boolean;
	id?: string;
	multiple?: boolean;
	loading?: boolean;
	groupBy?: string;
	disabled?: boolean;
	tooltipText?: string;
	showTooltip?: boolean;
};

const styles = {
	label: {
		fontSize: 13,
		fontWeight: 600,
		paddingBottom: 1,
		color: 'text.primary',
		lineHeight: '15.6px',
		textTransform: 'capitalize',
	},
	textField: {
		'& .MuiInputBase-root': {
			padding: '4px',

			'& input': {
				fontSize: 13,
				'&::placeholder': {
					color: (theme: Theme) => theme.palette.text.secondary,
					opacity: 1,
					fontSize: 13,
					textTransform: 'capitalize',
				},
			},
			'&:hover fieldset': {
				borderColor: (theme: Theme) => theme.palette.primary.main,
			},
			borderRadius: 2,
			'& fieldset': {
				borderColor: (theme: Theme) => theme.palette.text.primary,
			},
		},
	},
	textEllipsis: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
};

const GROUPS: Record<string, string> = {
	lp: 'Limited Partners',
	gp: 'General Partners',
	fund: 'Funds',
	portco: 'Portfolio Companies',
	isStandard: 'Standard',
};

export const Autocomplete = ({
	name = '',
	label = '',
	placeholder = '',
	loading = false,
	options = [],
	optionKey = 'name',
	onChange = () => {},
	onInputChange = () => {},
	onClose = () => {},
	customStyle = {},
	value = [],
	required = false,
	id,
	helperText,
	error,
	multiple = false,
	groupBy,
	tooltipText,
	showTooltip = false,
	...props
}: AutocompletePropsType) => {
	const getOptions = () => {
		if (groupBy === 'level') {
			const groupOrder = ['lp', 'gp', 'fund', 'portco'];
			return options
				.filter((option: any) => option.level !== 'ocio')
				.sort((a: any, b: any) => {
					const levelA = groupOrder.indexOf(a.level);
					const levelB = groupOrder.indexOf(b.level);
					return levelA - levelB;
				});
		} else if (groupBy) {
			return options.sort((a: any, b: any) =>
				`${a[groupBy]}`.localeCompare(`${b[groupBy]}`),
			);
		} else {
			return options;
		}
	};

	return (
		<>
			<Grid container gap={1}>
				<InputLabel sx={styles.label}>{`${label} ${required ? '*' : ''}`}</InputLabel>
				{tooltipText && (
					<Tooltip
						title={
							<Typography fontSize={12} sx={{ textTransform: 'none' }}>
								{tooltipText}
							</Typography>
						}
						placement="bottom-start"
					>
						<InfoIcon sx={{ height: '14px', width: '14px' }} />
					</Tooltip>
				)}
			</Grid>
			<MuiAutocomplete
				multiple={multiple}
				id={id}
				loading={loading}
				onClose={onClose}
				options={!loading ? getOptions() : []}
				groupBy={(option) =>
					(groupBy &&
						option[groupBy] &&
						GROUPS[typeof option[groupBy] === 'boolean' ? groupBy : option[groupBy]]) ||
					''
				}
				renderOption={(props, option) => {
					const optionValue =
						option.level === 'fund'
							? `${option[optionKey]} (${option.organization.name})`
							: option[optionKey];
					return (
						<>
							{showTooltip ? (
								<Tooltip title={<Typography>{optionValue}</Typography>}>
									<MenuItem
										{...props}
										divider
										key={option.id}
										sx={{ textTransform: 'capitalize' }}
									>
										<Typography sx={styles.textEllipsis}>{optionValue}</Typography>
									</MenuItem>
								</Tooltip>
							) : (
								<MenuItem
									{...props}
									divider
									key={option.id}
									sx={{ textTransform: 'capitalize' }}
								>
									<Typography sx={styles.textEllipsis}>{optionValue}</Typography>
								</MenuItem>
							)}
						</>
					);
				}}
				handleHomeEndKeys
				onInputChange={(_event, value, reason) => {
					if (reason === 'input' || reason === 'clear') onInputChange(value);
				}}
				ListboxProps={{ sx: { py: 0 } }}
				getOptionLabel={(option) =>
					option.level === 'fund'
						? `${option[optionKey]} (${option.organization.name})`
						: option[optionKey]
				}
				filterSelectedOptions
				sx={{ ...styles.textField, ...customStyle }}
				renderInput={(params) => (
					<TextField
						{...params}
						helperText={helperText}
						placeholder={placeholder}
						error={error}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading && <CircularProgress color="inherit" size={20} />}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				)}
				value={value}
				onChange={(_event, value) => {
					onChange({ target: { name, value } });
				}}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				popupIcon={<Image src={DropdownIcon} alt="icon" />}
				{...props}
			/>
		</>
	);
};
