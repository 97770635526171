import { useRouter } from 'next/router';
import Image from 'next/image';
import React from 'react';
import { Button } from './Button';
import useNotification from '@/hooks/useNotification';
import CopyIcon from '@/assets/icons/Copy.svg';

export const CopyButton = () => {
	const router = useRouter();
	const [sendNotification] = useNotification();

	const handleCopy = () => {
		const path = router.asPath;
		const generatePath = `${window.location.origin}${path}`;
		navigator.clipboard.writeText(generatePath);
		sendNotification({ msg: 'Link copied to clipboard', variant: 'success' });
	};

	return (
		<Button
			text="Copy"
			startIcon={<Image src={CopyIcon} alt="CopyIcon" />}
			variant="text"
			onClick={handleCopy}
		/>
	);
};
