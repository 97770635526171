import { useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '@/store/slices';
import { useMixpanelService } from '@/store/services/mixpanel.service';
import { MIXPANEL_TOKEN } from '@/utils/constants';
import { getDefaultAccount, isSuperAdmin } from '@/utils/helpers';

const useMixpanel = () => {
	const { data: user } = useSelector((state: RootState) => state.me);
	const { logMixpanelEvent } = useMixpanelService();

	const initializeMixpanel = (email?: string) => {
		if (!MIXPANEL_TOKEN) return;
		mixpanel.init(MIXPANEL_TOKEN, {
			track_pageview: 'url-with-path',
			persistence: 'localStorage',
			ip: true,
		});
		mixpanel.identify(user?.email || email); // if empty will use default mixpanel distinct_id
	};

	const track = (eventName: string, properties?: Record<any, any>) => {
		if (!MIXPANEL_TOKEN || !(mixpanel as any).__loaded) return;
		let workspaceProps = {};

		if (user) {
			if (isSuperAdmin(user)) {
				workspaceProps = {
					user_workspace: 'Universe',
					user_role: 'Super Admin',
				};
			} else {
				const defaultAccount = getDefaultAccount(user)!;
				workspaceProps = {
					user_workspace: defaultAccount.organization.name,
					user_role: defaultAccount.role.name,
				};
			}
		}

		const eventProperties = {
			distinct_id: user?.email, // if empty will use default mixpanel distinct_id
			...properties,
			...workspaceProps,
			$insert_id: uuidv4(),
			time: Date.now(),
		};

		mixpanel.track(eventName, eventProperties, () => {
			logMixpanelEvent({
				eventName,
				insertId: eventProperties.$insert_id,
			});
		});
	};

	const resetMixpanel = () => {
		if (!MIXPANEL_TOKEN) return;
		mixpanel.reset();
	};

	return { initializeMixpanel, track, resetMixpanel };
};

export default useMixpanel;
