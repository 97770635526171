import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLookerTemplateService } from '@/store/services/lookerTemplate.service';
import { RootState } from '@/store/slices';
import {
	formatPeriod,
	getDefaultAccount,
	getPeriodDateRange,
	isSuperAdmin,
} from '@/utils/helpers';
import {
	LookerDashboardType,
	FundType,
	OrganizationType,
	PeriodType,
	UserType,
} from '@/utils/types';

type LookerDashboardPropsType = {
	lookerDashboard: LookerDashboardType;
	showPeriod?: boolean;
};

const getLPName = (organization: OrganizationType) => {
	if (organization.level === 'lp') return organization.name;
	if (organization.level === 'gp') return organization.parentOrganization?.name || null;
	if (organization.level === 'portco')
		return organization.portco!.fund!.organization!.parentOrganization?.name || null;
	return null;
};

const getGPName = (organization: OrganizationType) => {
	if (organization.level === 'gp') return organization.name;
	if (organization.level === 'portco')
		return organization.portco!.fund!.organization!.name;
	return null;
};

const getFundName = (organization: OrganizationType) =>
	organization.level === 'portco' && organization.portco
		? organization.portco.fund!.name
		: null;

const getPortcoName = (organization: OrganizationType) =>
	organization.level === 'portco' && organization.portco ? organization.name : null;

const payloadForUserOrganization = (user: UserType) => {
	if (!isSuperAdmin(user) && user.userOrganizationRoles.length > 0) {
		const { organization } = getDefaultAccount(user!)!;
		return {
			user_lp: getLPName(organization) || '%, NULL',
			user_investor: getGPName(organization) || '%, NULL',
			user_fund: getFundName(organization) || '%, NULL',
			user_investment: getPortcoName(organization) || '%, NULL',
		};
	} else {
		return {
			user_lp: '%, NULL',
			user_investor: '%, NULL',
			user_fund: '%, NULL',
			user_investment: '%, NULL',
		};
	}
};

const payloadForDashboardOrganization = (
	organization?: OrganizationType,
	fund?: FundType,
) => {
	if (!organization && !fund) return {};
	const org = (fund?.organization ?? organization)! as OrganizationType;
	return {
		dashboard_lp: getLPName(org),
		dashboard_investor: getGPName(org),
		dashboard_fund: fund?.name ?? getFundName(org),
		dashboard_investment: getPortcoName(org),
	};
};

const payloadForPeriod = (period?: PeriodType, showPeriod = false) => {
	if (!period) return {};
	const { endDate } = getPeriodDateRange(period);
	return {
		year: period.timePeriod === 'annually' ? `${period.year}` : null,
		period: period.timePeriod !== 'annually' || showPeriod ? formatPeriod(period) : null,
		report_end_date: `before ${endDate},${endDate}`,
	};
};

export const LookerDashboard = ({
	lookerDashboard,
	showPeriod,
}: LookerDashboardPropsType) => {
	const [lookerDashboardLink, setLookerDashboardLink] = useState<string>('');
	const { getLookerDashboardLink } = useLookerTemplateService();
	const { data: user } = useSelector((state: RootState) => state.me);

	const getLookerDashboardLinkCallback = useCallback(
		async (data: LookerDashboardType) => {
			if (isEmpty(data) || !user) return;
			const { lookerTemplate, period, organization, fund } = data;
			const payload = {
				url: lookerTemplate.url,
				model: lookerTemplate.model,
				theme: lookerTemplate.theme,
				hide_filter: lookerTemplate.hideFilter,
				user_id: user!.id,
				user_name: user!.userName,
				...payloadForUserOrganization(user!),
				...payloadForDashboardOrganization(organization, fund),
				...payloadForPeriod(period, showPeriod),
			};
			const response = await getLookerDashboardLink(payload);
			setLookerDashboardLink(response.link);
		},
		[getLookerDashboardLink, user, showPeriod],
	);

	useEffect(() => {
		getLookerDashboardLinkCallback(lookerDashboard);
	}, [lookerDashboard, getLookerDashboardLinkCallback]);

	return (
		<iframe
			src={lookerDashboardLink}
			style={{ border: 'none', width: '100%', height: '100%' }}
		/>
	);
};
