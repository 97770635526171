import { isEmpty } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Menu as MuiMenu,
	MenuItem,
	Grid,
	Theme,
	Typography,
	Box,
	CircularProgress,
} from '@mui/material';
import CheckBox from '@/assets/icons/Checkmark.svg';
import { GET_AUTH_ME, RESET_CHIPS_FILTERS } from '@/store/actions';
import { useAuthService } from '@/store/services/auth.service';
import { useUserOrganizationRoleService } from '@/store/services/userOrganizationRole.service';
import { RootState } from '@/store/slices';
import { APP_ROUTES } from '@/utils/constants';
import { isSuperAdmin, sortUserOrganizationRolesByLevel } from '@/utils/helpers';
import { AnchorOriginType, MenuItemType, TransformOriginType } from '@/utils/types';

const styles = {
	paper: {
		width: '250px',
		mt: 1,
		borderRadius: '8px',
		p: 1,
		background: (theme: Theme) => theme.palette.action.selected,
		'& ul': {
			p: 0,
		},
	},
	workspaceTitle: {
		cursor: 'default',
		height: '90px',
		borderRadius: '8px 8px 0px 0px',
		borderBottom: (theme: Theme) => `1px solid ${theme.palette.action.selected}`,
		'&:hover, &:focus, &:focus-visible': {
			background: 'white',
		},
	},
	menuItem: {
		fontSize: '13px',
		lineHeight: '15.6px',
		p: '16px',
		height: '48px',
		minWidth: '200px',
		color: (theme: Theme) => theme.palette.text.primary,
		background: (theme: Theme) => theme.palette.common.white,
		borderColor: (theme: Theme) => theme.borderColor.toolbar,
	},
	menuItemForFund: {
		cursor: 'default',
		background: 'white',
		borderBottom: (theme: Theme) => `1px solid ${theme.palette.action.selected}`,
		'&:hover': {
			background: 'white',
		},
	},
	fundLabel: {
		fontSize: '14px',
		color: (theme: Theme) => theme.borderColor.textField,
		py: 1,
		background: 'white',
	},
	loader: {
		color: (theme: Theme) => theme.palette.primary.main,
	},
	workspaceNameEllipses: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
};

type MenuPropType = {
	anchorEl: HTMLElement | null;
	anchorOrigin?: AnchorOriginType;
	transformOrigin?: TransformOriginType;
	handleClose: () => void;
};

export const WorkspaceMenu = ({
	anchorEl,
	anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	handleClose,
}: MenuPropType) => {
	const router = useRouter();
	const dispatch = useDispatch();
	const { getLoggedInUserDetails } = useAuthService();

	const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);
	const [selectedMenuItemId, setSelectedMenuItemId] = useState('');
	const { data: user } = useSelector((state: RootState) => state.me);
	const { impersonatedUserId } = useSelector((state: RootState) => state.auth);
	const { updateDefaultAccount, isLoadingUserOrganizationRole: isLoading } =
		useUserOrganizationRoleService();
	const updateDefaultAccountRef = useRef(updateDefaultAccount);

	const getAuthMe = useCallback(
		(impersonatedUserId?: string) => {
			dispatch(GET_AUTH_ME({ getLoggedInUserDetails, impersonatedUserId }));
		},
		[dispatch, getLoggedInUserDetails],
	);

	const groupOrganizationsWithAssociatedPortco = (menuItems: Record<string, any>) => {
		return menuItems.reduce((acc: Record<string, any>, menuItem: MenuItemType) => {
			if (menuItem.fund) {
				const fund = acc.find((item: Record<string, any>) => item.fund === menuItem.fund);
				if (fund) {
					fund.menuItems.push(menuItem);
				} else {
					acc.push({
						fund: menuItem.fund,
						menuItems: [menuItem],
					});
				}
			} else {
				acc.push({
					menuItems: [menuItem],
				});
			}

			return acc;
		}, []);
	};

	const getWorkspaces = useCallback(() => {
		const sortUserOrganizationRoles = sortUserOrganizationRolesByLevel(
			user!.userOrganizationRoles,
		);

		const workspaces = sortUserOrganizationRoles.map((userOrganizationRole) => ({
			id: userOrganizationRole.organization.id,
			component: () => (
				<Typography fontSize="14px" color="#49545B" sx={styles.workspaceNameEllipses}>
					{userOrganizationRole.organization.name}
				</Typography>
			),
			name: userOrganizationRole.organization.name,
			icon: userOrganizationRole.isDefaultAccount ? CheckBox : null,
			fund: userOrganizationRole.organization.portco?.fund?.name || null,
			onClick: async () => {
				const defaultAccount = sortUserOrganizationRoles?.find(
					(userOrganizationRole) => userOrganizationRole.isDefaultAccount,
				);
				if (userOrganizationRole.id !== defaultAccount?.id) {
					const response = await updateDefaultAccountRef.current(
						{
							organizationId: userOrganizationRole.organization.id,
							roleId: userOrganizationRole.role.id,
						},
						null,
					);
					if (!isEmpty(response)) {
						const route = APP_ROUTES.find((route) => route.path === router.pathname);
						const isPathAccessible = route?.visibleTo.includes(
							userOrganizationRole.organization.level,
						);
						if (isPathAccessible) {
							getAuthMe(impersonatedUserId);
						} else {
							router.replace('/').finally(() => getAuthMe());
						}
					}
				}
			},
		}));
		const groupedData = groupOrganizationsWithAssociatedPortco(workspaces);
		setMenuItems(groupedData as MenuItemType[]);
	}, [user, router, getAuthMe, impersonatedUserId]);

	useEffect(() => {
		if (user && !isSuperAdmin(user)) {
			getWorkspaces();
		}
	}, [user, getWorkspaces]);

	const handleMenuClick = async (
		clickHandler: () => Promise<void>,
		menuItemId: string,
	) => {
		setSelectedMenuItemId(menuItemId);
		await clickHandler();
		dispatch(RESET_CHIPS_FILTERS());
		handleClose();
	};

	return (
		<MuiMenu
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
			PaperProps={{ sx: styles.paper }}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
		>
			<MenuItem sx={{ ...styles.menuItem, ...styles.workspaceTitle }} disableRipple>
				<Grid container gap={1}>
					<Typography>Workspaces</Typography>
				</Grid>
			</MenuItem>
			<Box overflow="auto" maxHeight={300}>
				{menuItems.map((groupMenuItem: Record<string, any>, index: number) => (
					<Box key={index}>
						{groupMenuItem.fund && (
							<MenuItem sx={styles.menuItemForFund} disableRipple>
								<Typography sx={styles.fundLabel}>{groupMenuItem.fund}</Typography>
							</MenuItem>
						)}
						{groupMenuItem.menuItems.map((item: any, index: number) => (
							<MenuItem
								key={index}
								onClick={() => handleMenuClick(item?.onClick, item.id)}
								divider={index !== menuItems.length - 1}
								sx={{
									...styles.menuItem,
									borderRadius: menuItems.length - 1 === index ? '0px 0px 8px 8px' : '',
								}}
							>
								<Grid container direction="row" justifyContent="space-between">
									<Grid item xs={10}>
										<item.component />
									</Grid>
									<Grid item xs={2}>
										<Grid item container justifyContent="flex-end">
											{isLoading && item.id === selectedMenuItemId ? (
												<CircularProgress sx={styles.loader} size={'20px'} />
											) : (
												item?.icon && <Image src={item?.icon} alt={item.name} />
											)}
										</Grid>
									</Grid>
								</Grid>
							</MenuItem>
						))}
					</Box>
				))}
			</Box>
		</MuiMenu>
	);
};
