import { useAxios } from '@/hooks/useAxios';

export const useUserOrganizationRoleService = () => {
	const { patch, isLoading } = useAxios();

	const updateDefaultAccount = (
		data: { organizationId: string; roleId: string },
		message: string | null,
	) => patch('/user-organization-roles/update-default-account', data, message);

	return { updateDefaultAccount, isLoadingUserOrganizationRole: isLoading };
};
