import React, { FC, Fragment } from 'react';
import { Backdrop, CircularProgress, Stack, Typography, Theme } from '@mui/material';

type LoaderProps = {
	backdrop?: boolean;
	loadingText?: string;
};

export const Loader: FC<LoaderProps> = ({ backdrop = true, loadingText }) => {
	return (
		<Fragment>
			{backdrop ? (
				<Backdrop
					sx={{
						backgroundColor: 'transparent',
						color: (theme: Theme) => theme.palette.primary.main,
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={true}
				>
					<Stack gap={1} justifyContent="center" alignItems="center">
						<CircularProgress color="inherit" />
						<Typography>{loadingText || 'Loading...'}</Typography>
					</Stack>
				</Backdrop>
			) : (
				<Stack
					gap={1}
					justifyContent="center"
					alignItems="center"
					color={(theme: Theme) => theme.palette.primary.main}
				>
					<CircularProgress color="inherit" />
					<Typography>{loadingText || 'Loading...'}</Typography>
				</Stack>
			)}
		</Fragment>
	);
};
