/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useLogout } from './useLogout';
import useNotification from './useNotification';
import { store } from '@/store';
import { LOGOUT } from '@/store/actions';
import { RootState } from '@/store/slices';
import { CPV2_API_URL } from '@/utils/constants';
import { ErrorType, ResponseErrorType } from '@/utils/types';

const api = axios.create();

api.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		if (config.baseURL === CPV2_API_URL) {
			const { token } = store.getState().auth;
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

api.interceptors.response.use(
	(response: AxiosResponse) => {
		return response?.data || response;
	},
	(error: AxiosError<ResponseErrorType>) => {
		const errorMessage = error.response?.data?.error?.errorMessage || error.message;
		const statusCode = error.response?.data?.status || error.response?.status || 400;
		return Promise.reject({ errorMessage, statusCode });
	},
);

export const useAxios = (baseURL = CPV2_API_URL) => {
	const router = useRouter();
	const dispatch = useDispatch();
	const { logout } = useLogout();
	const [sendNotification] = useNotification();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { impersonatedUserId } = useSelector((state: RootState) => state.auth);
	const headers = impersonatedUserId ? { impersonatedUserId } : null;
	// This state is used in context of initial loading of the component.
	// The reason of using this initial state was that the above state "isLoading" was
	// causing the component to not show the loader because it was initialized with false.
	// Hence there was a sudden jerk of NoData component when the component was loading for the first time.
	// This state is initialized with true and is exported as "true" so to show the Loader component until the
	// data fetch is not completed.
	const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsInitialLoading(false);
	}, []);

	const handleApiRequest = useCallback(
		(request: () => Record<string, any>, notificationSuccess?: string | null) => {
			setIsLoading(true);
			return request()
				.then((data: Record<string, any> | Record<string, any>[]) => {
					if (notificationSuccess) {
						sendNotification({ msg: notificationSuccess, variant: 'success' });
					}
					return data;
				})
				.catch((error: ErrorType) => {
					sendNotification({ msg: error.errorMessage, variant: 'error' });
					if (error.statusCode === 403 && error.errorMessage === 'User is inactive') {
						if (router.pathname !== '/login') {
							logout({
								async openUrl() {
									window.location.replace('/login');
								},
							});
						}
						dispatch(LOGOUT());
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[sendNotification],
	);

	const get = useCallback(
		(url: string, params?: Record<string, any>) => {
			return handleApiRequest(() =>
				api.get(url, { params, baseURL, ...(headers && { headers }) }),
			);
		},
		[handleApiRequest, baseURL],
	);

	const post = useCallback(
		(
			url: string,
			data?: Record<string, any>,
			msg: string | null = 'Successfully Created',
		) => {
			return handleApiRequest(
				() => api.post(url, data, { baseURL, ...(headers && { headers }) }),
				msg,
			);
		},
		[handleApiRequest, baseURL],
	);

	const put = useCallback(
		(
			url: string,
			data?: Record<string, any>,
			msg: string | null = 'Successfully Updated',
		) => {
			return handleApiRequest(
				() => api.put(url, data, { baseURL, ...(headers && { headers }) }),
				msg,
			);
		},
		[handleApiRequest, baseURL],
	);

	const patch = useCallback(
		(
			url: string,
			data?: Record<string, any>,
			msg: string | null = 'Successfully Updated',
		) => {
			return handleApiRequest(
				() => api.patch(url, data, { baseURL, ...(headers && { headers }) }),
				msg,
			);
		},
		[handleApiRequest, baseURL],
	);

	const remove = useCallback(
		(
			url: string,
			data?: Record<string, any>,
			msg: string | null = 'Successfully Deleted',
		) => {
			return handleApiRequest(
				() => api.delete(url, { baseURL, ...(headers && { headers }), data }),
				msg,
			);
		},
		[handleApiRequest, baseURL],
	);

	return {
		get,
		post,
		put,
		patch,
		remove,
		isLoading: isInitialLoading || isLoading,
	};
};
