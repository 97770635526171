import { NextRouter } from 'next/router';
import dayjs from 'dayjs';
import { format, endOfMonth } from 'date-fns';
import { isEmpty } from 'lodash';
import { SurveyPDF } from 'survey-pdf';
import {
	CHIP_COLORS,
	DELIVERABLE_TASK_WORKFLOW_OPTIONS,
	GENERAL_TASK_WORKFLOW_OPTIONS,
	PERMISSION_LEVEL_OPTIONS,
	SURVEY_DATA_WORKFLOW_OPTIONS,
	USER_LEVELS,
} from './constants';
import {
	FilterOptionsType,
	DashboardType,
	EntityType,
	MentionItemType,
	OrganizationType,
	PeriodType,
	SelectOptionsType,
	TaskType,
	TreeNode,
	TreeNodeDataType,
	UserOrganizationRole,
	UserType,
	SurveyType,
	ApplicationRouteType,
	LevelType,
} from './types';
import { Editor } from '@/components';
import COUNTRIES from '@/store/services/flatfile/countries';
import { uploadFileToFilestack } from '@/store/services/filestack.service';

// Shows the bytes in MB, KB or B
export const formatBytes = (bytes: string | number) => {
	const numericBytes = +bytes;
	if (numericBytes >= 1024 * 1024) {
		return `${(numericBytes / (1024 * 1024)).toFixed(2)} MB`;
	} else if (numericBytes >= 1024) {
		return `${(numericBytes / 1024).toFixed(2)} KB`;
	} else {
		return `${numericBytes} Bytes`;
	}
};

// Return formatted date e.g. 'Jan 1, 2021 | 12:00'
export const formatDate = (dateString?: string): string =>
	dayjs(dateString).format('MMM D, YYYY | HH:mm');

export const formatPeriod = (period: PeriodType) => {
	return period.timePeriod === 'annually'
		? `${period.year}`
		: `${period.year} ${capitalize(period.correspondingNumber)}`;
};

export const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const downloadText = (str: string, mimeType: string, fileName: string) => {
	const file = new Blob([str], { type: mimeType });
	const element = document.createElement('a');
	element.href = URL.createObjectURL(file);
	element.download = fileName;
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};

export const downloadFile = async (fileUrl: string, name: string) => {
	const image = await fetch(fileUrl);
	const imageBlog = await image.blob();
	const imageURL = URL.createObjectURL(imageBlog);
	const link = document.createElement('a');
	link.href = imageURL;
	link.download = name;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const uploadFile = async (
	file: File,
	uploadProgressCallback?: (progress: number) => void,
) => {
	let imgUrl = null;
	if (file) {
		await uploadFileToFilestack({
			file,
			onSuccess: (url: string) => {
				imgUrl = url;
			},
			onError: (error: Error) => {
				throw error;
			},
			progress: (progress: number) => {
				uploadProgressCallback && uploadProgressCallback(progress);
			},
			maxSize: 1024 * 1024 * 20, // 20mb
		});
	}
	return imgUrl;
};

// This function passes the component height to Filestack so
// it can return a lighter optimized version of the image
export const optimizeImg = (imageUrl: string, height: number) => {
	const filestackUrl = 'https://cdn.filestackcontent.com/';
	const isFilestackUrl = imageUrl.match(filestackUrl);
	if (isFilestackUrl) {
		const index = filestackUrl.length;
		// Add extra height to guarantee resolution quality
		const newHeight = height + 300;
		return `${imageUrl.substring(
			0,
			index,
		)}resize=height:${newHeight}/${imageUrl.substring(index)}`;
	} else {
		return imageUrl;
	}
};

export const optimizeProfileImg = (url: string) => {
	if (url) {
		return /^blob:/.test(url) ? url : optimizeImg(url, 150);
	}
	return url;
};

export const getFullUrl = (path: string) => {
	return typeof window !== 'undefined'
		? `${window.location.origin}${path.startsWith('/') ? path : `/${path}`}`
		: '';
};

export const getCountries = () => {
	return Object.keys(COUNTRIES)
		.sort((a, b) => COUNTRIES[a].localeCompare(COUNTRIES[b]))
		.map((c) => ({
			name: `${COUNTRIES[c]} - ${c}`,
			id: c,
		}));
};

export const isSubmitButtonDisabled = (
	touched: Record<string, unknown>,
	errors: Record<string, unknown>,
) => {
	return Object.keys(touched).some((key: string) => errors[key]);
};

export const isSuperAdmin = (user: UserType) => user.permissionLevel === 'super_admin';

export const isHigherPermissionLevel = (
	permissionLevel1: string,
	permissionLevel2: string,
) => {
	const permissionLevels = PERMISSION_LEVEL_OPTIONS.map((p) => p.id);
	return (
		permissionLevels.indexOf(permissionLevel1) <
		permissionLevels.indexOf(permissionLevel2)
	);
};

export const hasPermission = (user: UserType | null, permission: string) =>
	user?.permissions?.includes(permission);

export const canAccessRoute = (
	user: UserType,
	{ visibleTo, permission }: ApplicationRouteType,
) =>
	visibleTo.includes(getDefaultAccount(user!)?.organization.level as LevelType) &&
	(!permission || hasPermission(user, permission));

export const snakeCaseToLabel = (value: string): string => value.replace(/_/g, ' ');

export const camelToSnakeCase = (key: string) =>
	key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const getChipColor = (text: string): string => CHIP_COLORS[text] || '#69A6B4';

export const idsToString = (options: SelectOptionsType[]) =>
	options
		?.map((option) => option.id)
		.join(',')
		.toString();

export const getWorkflow = (workflowId: string, taskType: string) => {
	if (taskType === 'deliverable') {
		return DELIVERABLE_TASK_WORKFLOW_OPTIONS.find(
			(workflow) => workflow.id === workflowId,
		);
	} else if (taskType === 'general') {
		return GENERAL_TASK_WORKFLOW_OPTIONS.find((workflow) => workflow.id === workflowId);
	} else {
		return SURVEY_DATA_WORKFLOW_OPTIONS.find((workflow) => workflow.id === workflowId);
	}
};

export const getPeriodDateRange = (
	period: PeriodType,
): { startDate: string; endDate: string } => {
	const { year, timePeriod, correspondingNumber } = period;
	let startDate = '';
	let endDate = '';

	switch (timePeriod) {
		case 'annually':
			startDate = `${year}-01-01`;
			endDate = `${year}-12-31`;
			break;

		case 'quarterly':
			const quarterStartDates: { [key: string]: string } = {
				q1: `${year}-01-01`,
				q2: `${year}-04-01`,
				q3: `${year}-07-01`,
				q4: `${year}-10-01`,
			};
			const quarterEndDates: { [key: string]: string } = {
				q1: `${year}-03-31`,
				q2: `${year}-06-30`,
				q3: `${year}-09-30`,
				q4: `${year}-12-31`,
			};

			startDate = quarterStartDates[correspondingNumber];
			endDate = quarterEndDates[correspondingNumber];
			break;

		case 'monthly':
			const monthStart = new Date(correspondingNumber + ' 1, ' + year);
			const monthEnd = endOfMonth(monthStart);
			startDate = format(monthStart, 'yyyy-MM-dd');
			endDate = format(monthEnd, 'yyyy-MM-dd');
			break;

		default:
			break;
	}

	return { startDate, endDate };
};

// BFS algorithm to find node by its ID
export const bfsSearch = (graph: TreeNode[], targetId: string) => {
	const queue = [...graph];

	while (queue.length > 0) {
		const currNode = queue.shift();
		if (currNode!.id === targetId) {
			return currNode!;
		}
		if (currNode!.children) {
			queue.push(...currNode!.children);
		}
	}
	return null; // Target node not found
};

export const getNodeData = (
	tree: TreeNode[],
	nodeId: string,
): TreeNodeDataType | undefined => {
	const node = bfsSearch(tree, nodeId);
	return node ? { id: nodeId, name: node.name, level: node.level } : undefined;
};

export const getDefaultOrganization = (user: Record<string, any>) => {
	return user.userOrganizationRoles?.find(
		(userOrgRole: any) => userOrgRole.isDefaultAccount,
	)?.organization;
};

/* Sort organizations by hierarchy (LP/GP/Portco), and alphabetically inside each of them
LP A
GP A
GP B
Portco A
Portco B
*/
export const sortUserOrganizationRolesByLevel = (
	userOrganizationRoles: UserOrganizationRole[],
): UserOrganizationRole[] => {
	const sortedOrganizations = sortOrganizationsByLevel(
		userOrganizationRoles.map(({ organization }) => organization),
	);
	return sortedOrganizations
		.map((org) =>
			userOrganizationRoles.find(({ organization }) => organization.id === org.id),
		)
		.filter(Boolean) as UserOrganizationRole[];
};

export const sortOrganizationsByLevel = (organization: OrganizationType[]) => {
	const sortedOrganizations = [...organization].sort((a, b) => {
		const level =
			USER_LEVELS.map((l) => l.id).indexOf(a.level) -
			USER_LEVELS.map((l) => l.id).indexOf(b.level);
		if (level !== 0) {
			return level; // Sort by hierarchy level (LP > GP > Portco)
		}
		return a.name.localeCompare(b.name); // Sort alphabetically within each hierarchy level
	});

	return sortedOrganizations;
};

export const getSelectedEntityName = (
	organizationsOptions: OrganizationType[],
	entityId: string,
) => {
	return organizationsOptions.find((org) => org.id === entityId)?.name;
};

export const formatLinkedTasksOptions = (linkedTasks: TaskType[]) =>
	linkedTasks.map((linkedTask) => {
		const organization = linkedTask.organization as OrganizationType;
		return {
			...linkedTask,
			customTaskName: `${linkedTask.name} (${organization.name})`,
		};
	});

export const getDefaultAccount = (user: UserType) => {
	return user?.userOrganizationRoles?.find(
		(userOrganizationRole) => userOrganizationRole.isDefaultAccount,
	);
};

export const getRandomInt = (minimum = 0, maximum = 79) =>
	Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

export const getRootUrl = (url = '', subdomain = false) => {
	// Remove the protocol part (http:// or https://)
	url = url.replace(/(https?:\/\/)?/i, '');

	// Split the URL by '/'
	const parts = url.split('/');

	// Get the first part which should contain the domain
	const domainPart = parts[0];

	// Split the domain by '.' to extract the subdomain and domain
	const domainParts = domainPart.split('.');

	// Check if the subdomain should be included
	if (!subdomain && domainParts.length > 2) {
		// Exclude the subdomain and convert the domain to lowercase
		return domainParts.slice(1).join('.').toLowerCase();
	}

	// Include the subdomain and convert the whole domain to lowercase
	return domainPart.toLowerCase();
};

export const mapMentionableUsers = (users: UserType[]): MentionItemType[] =>
	users.map((user) => ({
		id: user.id,
		value: user.userName,
		pictureUrl: user.pictureUrl,
	}));

/**
 * This function takes an array of nodes and a boolean flag as input.
 * It returns an object that categorizes node IDs based on their level.
 * If the 'hasFund' flag is true, IDs of nodes with 'fund' level are also included in the returned object.
 */
export const mapNodesToIdByLevel = (organization: TreeNodeDataType[], hasFund = false) =>
	organization.reduce(
		(acc, org) => {
			if (org.level === 'fund') {
				hasFund && acc.fund?.push(org.id);
			} else {
				acc.organization.push(org.id);
			}
			return acc;
		},
		{
			organization: [] as string[],
			...(hasFund ? { fund: [] as string[] } : {}),
		},
	);

// stackoverflow.com/a/12300351
export const dataURItoBlob = (dataURI: string): Blob => {
	const byteString = atob(dataURI.split(',')[1]);
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	const blob = new Blob([ab], { type: mimeString });
	return blob;
};

export const getPageTitle = () =>
	typeof window !== 'undefined' ? document.title.split('-')[0] : '';

export const getMixpanelTaskProps = (task: TaskType) => {
	const organization = task.organization as OrganizationType;
	return { task_name: task.name, organization: organization.name };
};

/**
 * Transforms a query object into a filter values object.
 * It maps 'organization' keys to node data from an organizations tree,
 * and ensures no empty values or arrays are added.
 */
export const getFilterValuesFromQuery = (
	query: Record<string, any>,
	filterChips: FilterOptionsType[],
	organizationsTree?: TreeNode[],
) => {
	return Object.entries(query).reduce(
		(acc, [param, value]) => {
			if (!filterChips.some((item) => item.id === param)) return acc;
			if (isEmpty(value)) return acc;
			if (param === 'organization' && organizationsTree) {
				const data = Array.isArray(value)
					? value.map((item) => getNodeData(organizationsTree, item))
					: [getNodeData(organizationsTree, value)];
				if (data.filter(Boolean).length > 0) {
					acc[param] = data.filter(Boolean);
				}
			} else {
				const data = Array.isArray(value) ? value : [value];
				if (data.length > 0) {
					acc[param] = data;
				}
			}
			return acc;
		},
		{} as Record<string, any>,
	);
};

export const getQueryParamsFromFilterValues = (
	query: Record<string, any>,
	filterValues: Record<string, any>,
) => {
	const newQuery = { ...query };
	for (const chip in filterValues) {
		newQuery[chip] =
			chip === 'organization'
				? filterValues[chip].map((org: TreeNodeDataType) => org.id)
				: filterValues[chip];
	}
	delete newQuery['view'];
	return newQuery;
};

export const replaceUrlQuery = (
	router: NextRouter,
	filterValues: Record<string, any>,
	view?: string,
) => {
	const query = getQueryParamsFromFilterValues(router.query, filterValues);
	if (router.pathname.startsWith('/tasks') && view === 'custom-view') {
		router.replace({
			pathname: '/tasks/custom-view',
			query,
		});
	} else if (!router.pathname.startsWith('/tasks')) {
		router.replace({
			pathname: router.pathname,
			query,
		});
	}
};

export const getEntityNames = (entities: EntityType[], ids: string[]) =>
	ids.map((id) => entities.find((e) => e.id === id)!.name).join(',');

// Get all entities related to task or dashboard
const getEntitiesForTaskOrDashboard = (taskOrDashboard: TaskType | DashboardType) => {
	const organization = taskOrDashboard.organization as OrganizationType;
	const fund = 'fund' in taskOrDashboard ? taskOrDashboard.fund : null;
	return [
		organization,
		organization?.portco?.fund,
		organization?.portco?.fund?.organization,
		organization?.portco?.fund?.organization?.parentOrganization,
		organization?.parentOrganization,
		fund,
		fund?.organization,
		fund?.organization?.parentOrganization,
	].filter(Boolean) as EntityType[];
};

const hasEntityNotes = (entity: EntityType) =>
	entity.entityNotes && entity.entityNotes !== '<p><br></p>';

export const getEntityNotesTabName = (entity: EntityType) =>
	hasEntityNotes(entity) ? `${entity.name} Notes` : null;

export const getEntityNotesTabComponent = (entity: EntityType) =>
	hasEntityNotes(entity) ? (
		<Editor
			readOnly={true}
			text={entity.entityNotes!}
			title={getEntityNotesTabName(entity)!}
			type="entityNotes"
		/>
	) : null;

export const getEntityNotesTabNames = (taskOrDashboard: TaskType | DashboardType) =>
	getEntitiesForTaskOrDashboard(taskOrDashboard)
		.map((entity) => getEntityNotesTabName(entity))
		.filter(Boolean);

export const getEntityNotesTabComponents = (taskOrDashboard: TaskType | DashboardType) =>
	getEntitiesForTaskOrDashboard(taskOrDashboard)
		.map((entity) => getEntityNotesTabComponent(entity))
		.filter(Boolean);

export const savePdf = (
	survey: SurveyType,
	surveyJSData: Record<string, any>,
	showInvisibleElements = true,
) => {
	// Replace multiple line breaks with a single line break for PDF because it doesn't support multiple line breaks
	const json = JSON.parse(
		JSON.stringify(survey.json).replace(/(<\/?br\s*?\/?\s*?>\s*){2,}/gi, '<br>'),
	);
	json.logo = '/TableclothLogo.svg';
	json.logoWidth = 202;
	json.logoHeight = 76;
	const surveyPdf = new SurveyPDF(json, {
		format: 'a3',
	});
	// Adds copyright and page number to the footer
	surveyPdf.onRenderFooter.add((_, canvas: any) => {
		canvas.drawText({
			text: `© ${new Date().getFullYear()} Tablecloth.io`,
			fontSize: 13,
		});
		canvas.drawText({
			text: 'Page ' + canvas.pageNumber + ' of ' + canvas.pageCount,
			fontSize: 10,
			horizontalAlign: 'right',
			verticalAlign: 'bottom',
			margins: { right: 15 },
		});
	});
	surveyPdf.showInvisibleElements = showInvisibleElements;
	surveyPdf.data = surveyJSData;
	surveyPdf.save(survey.name);
};
