import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { TextEditor } from '@/components';
import { DEFAULT_FORMAT_OPTIONS_WITH_MENTION } from '@/utils/constants';
import { MentionItemType, UserType } from '@/utils/types';

const styles = {
	editorStyle: {
		height: '400px',
	},
	emptyEditorContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '450px',
		maxWidth: '630px',
		borderRadius: '8px',
		border: '1px solid #606e77',
	},
};

type EditorProps = {
	codox?: Record<string, any>;
	docId?: string;
	handleUpdateText?: (_key: string, _value: string) => void;
	readOnly?: boolean;
	text?: string;
	title?: string;
	type?: 'summary' | 'notes' | 'entityNotes';
	user?: UserType | null;
	usersForMentions?: MentionItemType[];
};

export const Editor = ({
	codox,
	docId,
	handleUpdateText,
	readOnly = false,
	text,
	title,
	type = 'summary',
	user,
	usersForMentions,
}: EditorProps) => {
	const [isMentionableUsersUpdated, setIsMentionableUsersUpdated] = useState(false);

	const saveHandler = useRef(
		debounce((text) => {
			handleUpdateText!(type, text);
		}, 1000),
	);

	useEffect(() => {
		if (!usersForMentions) return;
		setIsMentionableUsersUpdated(true);

		const timeout = setTimeout(() => {
			setIsMentionableUsersUpdated(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [usersForMentions]);

	const handleChange = (editorText: string) => {
		saveHandler.current(editorText);
	};

	return (
		<Box sx={{ height: '100%' }}>
			<Grid container direction={'column'} height={'100%'} gap={3} wrap="nowrap">
				<Grid item>
					<Typography
						fontSize="18px"
						sx={{ textTransform: 'capitalize' }}
						color={(theme: Theme) => theme.textColor.primary}
					>
						{title || type}
					</Typography>
				</Grid>
				<Grid item flexGrow={1}>
					{isMentionableUsersUpdated ? (
						<Box sx={styles.emptyEditorContainer} />
					) : (
						<TextEditor
							atValues={usersForMentions}
							codox={codox}
							docId={`${docId}-${type}`}
							customStyles={styles.editorStyle}
							formatOptions={DEFAULT_FORMAT_OPTIONS_WITH_MENTION}
							isCollaborative={true}
							onChange={handleChange}
							value={text}
							user={user}
							readOnly={readOnly}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};
