import React, { useCallback, useEffect, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Menu as MuiMenu, Grid, Theme, Typography, Box } from '@mui/material';
import NotificationIcon from '@/assets/icons/NotificationIcon.svg';
import { Tabs, Button, NoData, NotificationMenuItem, Loader } from '@/components';
import useMixpanel from '@/hooks/useMixpanel';
import { PAGINATION_INITIAL_STATE } from '@/utils/constants';
import {
	AnchorOriginType,
	NotificationType,
	PaginationMetaType,
	TransformOriginType,
} from '@/utils/types';
import { NOTIFICATION_TYPE, WORKFLOW_STATUS } from '@/utils/enums';

const styles = {
	container: {
		height: '400px',
		overflowY: 'scroll',
	},
	paper: {
		width: '400px',
		mt: 2,
		borderRadius: '0px',
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		p: 0,
		'& ul, & li': {
			p: 0,
		},
		'&: hover': {
			backgroundColor: (theme: Theme) => theme.palette.common.white,
		},
	},
	menuItem: {
		fontSize: '13px',
		height: '48px',
		minWidth: '200px',
		background: (theme: Theme) => theme.palette.common.white,
		borderColor: (theme: Theme) => theme.borderColor.toolbar,
	},
	notificationHeader: {
		px: 3,
		pt: 3,
		background: (theme: Theme) => theme.palette.common.white,
	},
	heading: {
		fontSize: '18px',
		color: '#121212',
	},
	button: {
		fontSize: '13px',
		background: 'transparent',
		color: (theme: Theme) => theme.palette.secondary.light,
		lineHeight: '14.4px',
		textTransform: 'initial',
		p: 0,
		'&:hover': {
			background: 'transparent',
		},
	},
	loadingContainer: {
		height: '400px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
};

type NotificationMenuProps = {
	anchorEl: HTMLElement | null;
	anchorOrigin?: AnchorOriginType;
	transformOrigin?: TransformOriginType;
	handleClose: () => void;
	open: boolean;
	readNotificationsData: NotificationType[];
	unreadNotificationsData: NotificationType[];
	paginationMeta: PaginationMetaType;
	setPaginationMeta: React.Dispatch<React.SetStateAction<PaginationMetaType>>;
	isLoadingNotifications: boolean;
	getNotificationsCallback: (page: number, isRead: boolean) => void;
	selectedTab: number;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
	markAllAsReadCallback: () => void;
	markNotificationAsReadCallback: (notificationId: string) => void;
	markNotificationAsUnreadCallback: (notificationId: string) => void;
};

const NOTIFICATIONS_TABS = ['Unread', 'Read'];

export const NotificationsMenu = ({
	anchorEl,
	open,
	anchorOrigin = {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin = {
		vertical: 'top',
		horizontal: 'right',
	},
	handleClose,
	readNotificationsData,
	unreadNotificationsData,
	paginationMeta,
	setPaginationMeta,
	isLoadingNotifications,
	getNotificationsCallback,
	selectedTab,
	setSelectedTab,
	markAllAsReadCallback,
	markNotificationAsReadCallback,
	markNotificationAsUnreadCallback,
}: NotificationMenuProps) => {
	const router = useRouter();
	const loaderRef = useRef<HTMLDivElement | null>(null);
	const { track } = useMixpanel();

	useEffect(() => {
		if (open) {
			getNotificationsCallback(paginationMeta.page, selectedTab !== 0);
			track('Notifications Tab Opened', { tab: NOTIFICATIONS_TABS[0] });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getNotificationsCallback, open, paginationMeta.page, selectedTab]);

	const handleInfiniteScroll = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			const target = entries[0];

			if (
				target.isIntersecting &&
				paginationMeta.hasNextPage &&
				!isLoadingNotifications
			) {
				setPaginationMeta((prev) => ({ ...prev, page: prev.page + 1 }));
			}
		},
		[isLoadingNotifications, paginationMeta.hasNextPage, setPaginationMeta],
	);

	useEffect(() => {
		let observerRefValue: HTMLDivElement | null = null;
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.5,
		};
		const observer = new IntersectionObserver(handleInfiniteScroll, options);
		if (loaderRef.current) {
			observer.observe(loaderRef.current);
			observerRefValue = loaderRef.current;
		}
		return () => {
			if (observerRefValue) {
				observer.unobserve(observerRefValue as HTMLDivElement);
			}
		};
	}, [handleInfiniteScroll, loaderRef, paginationMeta.hasNextPage]);

	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTab(newValue);
		setPaginationMeta(PAGINATION_INITIAL_STATE as PaginationMetaType);
		track('Notifications Tab Opened', { tab: NOTIFICATIONS_TABS[newValue] });
	};

	const getPathFromNotification = (notification: NotificationType) => {
		if (notification.file) {
			return '/files';
		}

		if (notification.dashboard) {
			let path = `/dashboards/${notification.dashboard.friendlyId}`;

			if (notification.type === NOTIFICATION_TYPE.USER_MENTIONED_IN_DASHBOARD_SUMMARY) {
				path += '?tab=summary';
			}

			return path;
		}

		if (notification.organization) {
			switch (notification.organization.level) {
				case 'lp':
					return 'limited-partners';
				case 'gp':
					return 'general-partners';
				default:
					return 'portfolio-companies';
			}
		}

		if (notification.task) {
			let path = `/tasks/details/TC-${notification.task.friendlyId}`;

			if (notification.type === NOTIFICATION_TYPE.USER_MENTIONED_IN_TASK_NOTES) {
				path += '?tab=notes';
			} else if (notification.type === NOTIFICATION_TYPE.USER_MENTIONED_IN_TASK_SUMMARY) {
				path += '?tab=summary';
			}

			return path;
		}
	};

	const handleNotificationClick = (notification: NotificationType) => {
		if (selectedTab === 0) {
			markNotificationAsReadCallback(notification.id);
		}

		const path = getPathFromNotification(notification);
		if (path) {
			handleClose();
			router.push(path);
		}
		const notificationText = getNotificationText(notification);
		track('Notification Viewed', { notification_text: notificationText });
	};

	const getNotificationText = (notification: NotificationType) => {
		const notificationTextHTML = renderToStaticMarkup(getNotificationHtml(notification));
		const parser = new DOMParser();
		const parsedHTML = parser.parseFromString(notificationTextHTML, 'text/html');
		return parsedHTML.body.textContent || '';
	};

	const getNotificationHtml = (notification: NotificationType) => {
		const { type, task, organization, referencedUser, dashboard, file } = notification;
		switch (type) {
			case NOTIFICATION_TYPE.START_TASK_REMINDER:
				return (
					<>
						<b>{task?.name}</b> is ready to begin
					</>
				);

			case NOTIFICATION_TYPE.DUE_TASK_REMINDER:
				return (
					<>
						<b>{task?.name}</b> is due soon
					</>
				);

			case NOTIFICATION_TYPE.OVERDUE_TASK_REMINDER:
				return (
					<>
						<b>{task?.name}</b> is overdue
					</>
				);

			case NOTIFICATION_TYPE.OVERDUE_TASK_FOLLOWUP_REMINDER:
				return (
					<>
						<b>{task?.name}</b> was due on <b>{task && task.dueDate}</b>
					</>
				);

			case NOTIFICATION_TYPE.TASK_SUBMITTED:
				return (
					<>
						<b>{task?.name}</b> is ready for you to review.
					</>
				);

			case NOTIFICATION_TYPE.TASK_MARKED_AS_IN_REVIEW:
				return (
					<>
						<b>{task?.name}</b> is ready for you to review.
					</>
				);

			case NOTIFICATION_TYPE.TASK_MOVED_TO_WORKING_FROM_IN_REVIEW:
				return (
					<>
						<b>{referencedUser?.userName}</b> moved <b>{task?.name}</b>
						{' back to “Working” from “In Review”'}
					</>
				);

			case NOTIFICATION_TYPE.TASK_MARKED_AS_NEEDS_ATTENTION:
				return (
					<>
						<b>{task?.name}</b> has been reviewed and needs attention.
					</>
				);

			case NOTIFICATION_TYPE.TASK_MARKED_AS_REQUIRES_RESUBMISSION:
				return (
					<>
						<b>{task?.name}</b> has been reviewed and requires resubmission.
					</>
				);

			case NOTIFICATION_TYPE.TASK_MOVED_TO_FEEDBACK_SHARED_WITH_CONTACT_FROM_NEEDS_ATTENTION:
				return (
					<>
						<b>{task?.name}</b> has been reviewed and needs attention.
					</>
				);

			case NOTIFICATION_TYPE.USER_FEEDBACK_ON_TASK:
				return (
					<>
						<b>{referencedUser?.userName}</b> has responded to feedback on{' '}
						<b>{task?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.EXTERNAL_USER_COMMENT_ON_TASK:
				return (
					<>
						<b>{referencedUser?.userName}</b> commented on <b>{task?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.USER_MENTIONED_IN_TASK_COMMENT:
			case NOTIFICATION_TYPE.USER_MENTIONED_IN_TASK_NOTES:
			case NOTIFICATION_TYPE.USER_MENTIONED_IN_TASK_SUMMARY:
				return (
					<>
						<b>{referencedUser?.userName}</b> mentioned you in <b>{task?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.USER_COMMENTED_ON_TASK:
				return (
					<>
						<b>{referencedUser?.userName}</b> commented on <b>{task?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.USER_MENTIONED_IN_DASHBOARD_SUMMARY:
			case NOTIFICATION_TYPE.USER_MENTIONED_IN_DASHBOARD_COMMENT:
				return (
					<>
						<b>{referencedUser?.userName}</b> mentioned you in <b>{dashboard?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.TASK_ALL_DATA_RECEIVED:
				return (
					<>
						All the data has been received for <b>{task?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.DASHBOARD_SHARED_WITH_USER:
				return (
					<>
						<b>{dashboard?.name}</b> has been shared with you.
					</>
				);

			case NOTIFICATION_TYPE.NEW_ORGANIZATION_CREATED:
				return (
					<>
						A new <b>{organization?.level}</b> has been created:{' '}
						<b>{organization?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.NEW_TASK_ADDED:
				return (
					<>
						<b>{task?.name}</b> has been shared with you.
					</>
				);

			case NOTIFICATION_TYPE.NEW_FILE_ADDED:
				return (
					<>
						<b>{referencedUser?.userName}</b> uploaded the following file
						{organization && ` for ${organization?.name}`}: <b>{file?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.NEW_FILE_ADDED_THROUGH_SECURE_LINK:
				return (
					<>
						New secure file uploaded: <b>{file?.name}</b>
					</>
				);

			case NOTIFICATION_TYPE.NEW_USER_ADDED_TO_TASK:
				return (
					<>
						<b>{task?.name}</b> has been shared with you
					</>
				);

			case NOTIFICATION_TYPE.TASK_SURVEY_REOPENED:
				return (
					<>
						<b>{referencedUser?.userName}</b> reopened <b>{task?.name}</b>
					</>
				);
			case NOTIFICATION_TYPE.TASK_WORKFLOW_NOT_UPDATED_FOR_7_DAYS:
				return (
					<>
						<b>{task?.name}</b>
						{task?.workflowStatus === WORKFLOW_STATUS.REQUIRES_RESUBMISSION
							? 'requires resubmission'
							: 'needs attention'}
						.
					</>
				);

			case NOTIFICATION_TYPE.TASK_WORKFLOW_NOT_UPDATED_FOR_10_DAYS:
				return (
					<>
						<b>{task?.name}</b> has not been updated for 10 days.
					</>
				);

			default:
				return <></>;
		}
	};

	return (
		<MuiMenu
			sx={{ height: '800px' }}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={handleClose}
			PaperProps={{ sx: styles.paper }}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
		>
			<Grid container gap={2}>
				<Grid item sx={styles.notificationHeader} xs={12}>
					<Grid container gap={1.25} alignItems="center">
						<Grid item mt={0.5}>
							<Image
								src={NotificationIcon}
								alt="Notification Icon"
								width={18.67}
								height={18.67}
							/>
						</Grid>
						<Grid item>
							<Typography variant="body1" sx={styles.heading}>
								Notifications
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Tabs
						padding={'24px 24px 12px 24px'}
						labels={NOTIFICATIONS_TABS}
						selectedTab={selectedTab}
						handleTabChange={handleTabChange}
						background="#f9f9fb"
						variant="fullWidth"
						components={[
							<Grid container gap={1} key={'unRead'}>
								<Grid item xs={12} textAlign="right">
									<Button
										text="Mark all as read"
										customStyles={styles.button}
										onClick={() => markAllAsReadCallback()}
									/>
								</Grid>
								<Grid item xs={12} sx={styles.container}>
									{isLoadingNotifications && paginationMeta.page === 1 ? (
										<Box sx={styles.loadingContainer}>
											<Loader backdrop={false} />
										</Box>
									) : unreadNotificationsData.length === 0 ? (
										<NoData
											primaryText="Congrats! You have seen all notifications."
											primaryTextSize="14px"
											backgroundColor="transparent"
										/>
									) : (
										unreadNotificationsData.map((notification: NotificationType) => (
											<NotificationMenuItem
												key={notification.id}
												notification={notification}
												notificationText={getNotificationHtml(notification)}
												onClick={() => handleNotificationClick(notification)}
												markNotificationAsRead={markNotificationAsReadCallback}
											/>
										))
									)}
									{selectedTab === 0 && (
										<div ref={loaderRef} style={{ height: '20px' }} />
									)}
								</Grid>
							</Grid>,
							<Grid key={'read'} sx={{ height: '426.5px', overflowY: 'scroll' }}>
								{isLoadingNotifications && paginationMeta.page === 1 ? (
									<Box sx={styles.loadingContainer}>
										<Loader backdrop={false} />
									</Box>
								) : readNotificationsData.length === 0 ? (
									<NoData
										primaryText="You haven't read any notification."
										primaryTextSize="14px"
										backgroundColor="transparent"
									/>
								) : (
									readNotificationsData.map((notification: NotificationType) => (
										<NotificationMenuItem
											key={notification.id}
											notification={notification}
											notificationText={getNotificationHtml(notification)}
											onClick={() => handleNotificationClick(notification)}
											markNotificationAsUnread={markNotificationAsUnreadCallback}
										/>
									))
								)}
								<div ref={loaderRef} style={{ height: '20px' }} />
							</Grid>,
						]}
					/>
				</Grid>
			</Grid>
		</MuiMenu>
	);
};
