import React from 'react';
import { Tabs as MuiTabs, Tab, Box, Theme } from '@mui/material';

type TabsPropsType = {
	labels: string[];
	selectedTab: number | false;
	handleTabChange: (_event: React.SyntheticEvent<Element, Event>, _value: number) => void;
	components?: React.ReactNode[];
	padding?: number | string;
	tabHeaderPadding?: string;
	background?: string;
	variant?: 'scrollable' | 'fullWidth';
};

const styles = {
	container: {
		backgroundColor: '#fff',
		padding: '0 24px',
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.toolbar}`,
	},
	tab: {
		minWidth: '150px',
		fontSize: '13px',
		fontWeight: 600,
		lineHeight: '16px',
		textTransform: 'capitalize',
	},
};

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const Tabs = ({
	labels = [],
	selectedTab = 0,
	handleTabChange,
	components,
	padding = 3,
	background,
	tabHeaderPadding,
	variant = 'scrollable',
}: TabsPropsType) => {
	return (
		<>
			<Box sx={{ ...styles.container, padding: tabHeaderPadding || '3px 24px 0' }}>
				<MuiTabs value={selectedTab} onChange={handleTabChange} variant={variant}>
					{labels.map((label, index) => (
						<Tab label={label} {...a11yProps(index)} sx={styles.tab} key={index} />
					))}
				</MuiTabs>
			</Box>
			{components && selectedTab !== false && (
				<Box
					p={padding}
					sx={{ height: 'calc(100% - 52px)', overflow: 'auto', background }}
				>
					{components[selectedTab]}
				</Box>
			)}
		</>
	);
};
