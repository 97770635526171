import { useCallback } from 'react';
import { useAxios } from '@/hooks/useAxios';
import { NotificationsFiltersType } from '@/utils/types';

export const useNotificationService = () => {
	const { get, isLoading, patch } = useAxios();

	const getNotifications = useCallback(
		(queryFilters: NotificationsFiltersType) =>
			get('/notifications', { ...queryFilters, take: 15 }),
		[get],
	);

	const markAllNotificationsAsRead = () => patch('/notifications/mark-as-read', {}, null);

	const markNotificationAsRead = (id: string) =>
		patch(`/notifications/mark-as-read/${id}`, {}, null);

	const markNotificationAsUnread = (id: string) =>
		patch(`/notifications/mark-as-unread/${id}`, {}, null);

	return {
		getNotifications,
		markAllNotificationsAsRead,
		markNotificationAsRead,
		markNotificationAsUnread,
		isLoadingNotifications: isLoading,
	};
};
